import { createFileRoute } from "@tanstack/react-router";

import AmountInput from "../../../components/AmountInputForm";
import RecipientDetailsForm from "../../../components/RecipientDetailsForm";
import PaymentDetails from "../../../components/PaymentDetails";
import {
  UPDATE_SEND_FORM_SECTION,
  useAppState,
} from "../../../contexts/AppContext";

export const Route: any = createFileRoute("/_app/send/")({
  component: Send,
});

function Send() {
  const { state, dispatch } = useAppState();
  const updateFormSection = (value: string) => {
    dispatch({ type: UPDATE_SEND_FORM_SECTION, payload: value });
  };
  return (
    <div className="bg-white mx-auto lg:w-2/4 w-full  p-4 rounded-lg">
      {state.activeSendFormScreen === "amountInput" && (
        <AmountInput
          handleContinue={() => updateFormSection("recipientDetails")}
        />
      )}
      {state.activeSendFormScreen === "recipientDetails" && (
        <RecipientDetailsForm
          handleContinue={() => updateFormSection("paymentInformation")}
          handleBack={() => updateFormSection("amountInput")}
        />
      )}
      {state.activeSendFormScreen === "paymentInformation" && (
        <PaymentDetails />
      )}
    </div>
  );
}
