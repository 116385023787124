import React from "react";
import cx from "classnames";
import { FieldError } from "react-hook-form";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
}

const TextInput: React.FC<TextInputProps> = React.forwardRef<
  HTMLInputElement,
  Omit<TextInputProps, "ref">
>(({ error, className, name, ...rest }, ref) => {
  return (
    <div className="form-control">
      <input
        className={cx(
          "appearance-none block w-full text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-primary",
          className,
          {
            "border-red-500 outline-red-500 focus:border-red-500 focus:outline-red-500":
              !!error,
          },
        )}
        ref={ref}
        name={name}
        {...rest}
      />
      {error && (
        <p className="text-red-500 text-xs italic mt-1">{error?.message}</p>
      )}
    </div>
  );
});

export default TextInput;
