import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

import { baseUrl as baseURL } from "../constants";
import { showToast } from "../utils/toast";
import { getUserToken } from "../utils/firebase";

const axiosInstance = axios.create({
  baseURL,
});

const onRequest = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const token = await getUserToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  showToast(error.message || "An error occured", "error");
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError) => {
  showToast(error.message);
  return Promise.reject(error);
};

// https://axios-http.com/docs/interceptors
axiosInstance.interceptors.request.use(onRequest, onRequestError);
// axiosInstance.interceptors.response.use(onResponse, onResponseError);

export default axiosInstance;
