import React from "react";

const Spacer = ({ size = "sm" }: { size?: string }) => {
  const spacing = {
    sm: "my-2",
    md: "my-4",
    lg: "my-8",
  }[size];

  return <div className={spacing}></div>;
};

export default Spacer;
