import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { AppRoutes } from "../utils";

export const Route = createFileRoute("/_auth")({
  notFoundComponent: () => {
    return <p>The Page you requested was not found</p>;
  },
  beforeLoad: ({
    context: {
      app: {
        state: { user },
      },
    },
  }) => {
    if (user) {
      return redirect({ to: AppRoutes.DASHBOARD });
    }
  },
  component: () => {
    const currentYear = new Date().getFullYear();

    return (
      <div className="min-h-screen flex">
        <aside className="bg-primary-complement md:col-span-1 hidden lg:flex justify-center items-center w-2/6">
          <div className="flex flex-col items-center">
            <img
              className="text-center"
              src="/logo.svg"
              alt="Passport Logo"
              width={80}
            />
            <h2 className="text-4xl mt-3 text-center">
              <span className="text-primary">Passport</span>
              <span className="text-secondary">monie</span>
            </h2>
          </div>
        </aside>

        <main className="md:col-span-2 lg:px-11 md:px-4 py-5 mx-auto">
          <div className="auth-layout">
            <Outlet />
          </div>

          <footer className="pt-2 grow-0 border-t border-t-primary text-center text-xs">
            <span>© {currentYear} Passportmonie. All Rights Reserved.</span>
          </footer>
        </main>
      </div>
    );
  },
});
