export const validationSchema = {
  password: {
    required: "Password is required",
    pattern: {
      value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
      message:
        "Password (Uppercase, Lowercase, Number, Special Character and minimum of 8 characters",
    },
  },
};
