import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
  useQueryClient,
  useMutation,
} from "@tanstack/react-query";

import { baseUrl } from "../../../constants";
import { apiClient } from "../../api";
import { TRANSACTIONS_API_RESPONSE, TRANSACTION_API_RESPONSE } from "./types";
import keys from "./keys";

export const transactionsResourcePath = "businesses-orders";

export const useGetTransactions = (url: string, pageNumber: string = "1") => {
  const hash = [keys.read, `${pageNumber}`];
  const {
    isLoading,
    data,
    isSuccess,
    error,
  }: UseQueryResult<TRANSACTIONS_API_RESPONSE, unknown> = useQuery(hash, () =>
    apiClient.get({ url }),
  );

  return { isLoading, data, isSuccess, error };
};

export const useGetTransaction = (id: string) => {
  const hash = [keys.read, `${id}`];
  const {
    isLoading,
    data,
    error,
  }: UseQueryResult<TRANSACTION_API_RESPONSE, unknown> = useQuery(hash, () =>
    apiClient.get({ url: `${baseUrl}/${transactionsResourcePath}/${id}` }),
  );

  return { isLoading, data, error };
};

const useUpdateTransactionStatus = (options = {}) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, data, isSuccess } = useMutation(apiClient.post, {
    ...options,
    mutationKey: [keys.create],
    onSuccess: () => {
      queryClient.invalidateQueries([keys.read]);
    },
    onError: () => {},
  });

  return {
    mutate: (bodyArg: { data: string; id: string }) => {
      const { data, id } = bodyArg;
      const body = { data: data };

      return mutate({
        url: `${baseUrl}/${transactionsResourcePath}/${id}`,
        body: data,
      });
    },
    isLoading,
    data,
    isSuccess,
  };
};
