import { useRef, useEffect, ReactNode } from "react";

import styles from "./Dialog.module.css";

interface DialogProps {
  children: ReactNode;
  isOpen: boolean;
  //   onClose: () => void;
}

const Dialog = ({ isOpen, children }: DialogProps) => {
  const dialogRef = useRef<null | HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (isOpen) {
      dialog?.showModal();
    } else {
      dialog?.close();
    }
  }, [isOpen]);

  return (
    <dialog
      className="fixed pin z-50 overflow-auto flex backdrop:bg-gray-400 backdrop:bg-opacity-50 rounded-md"
      ref={dialogRef}
    >
      {children}
    </dialog>
  );
};
export default Dialog;
