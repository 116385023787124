import { RouterProvider, createRouter } from "@tanstack/react-router";
import "react-toastify/dist/ReactToastify.css";

import { useAppState } from "./contexts/AppContext";
import { routeTree } from "./routeTree.gen";

const DefaultNotFoundRoute = () => {
  return <h3>Page not found</h3>;
};

const router: any = createRouter({
  routeTree,
  context: { app: undefined! },
  defaultNotFoundComponent: () => <DefaultNotFoundRoute />,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export default function App() {
  const app = useAppState();

  return (
    <>
      <RouterProvider router={router} context={{ app }} />
    </>
  );
}
