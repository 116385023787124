import { createFileRoute } from "@tanstack/react-router";

export const Route: any = createFileRoute("/_app/cards/")({
  component: Cards,
});

function Cards() {
  return (
    <div className="orders-page">
      <div className="overflow-x-hidden rounded-lg relative flex justify-center p-6 md:p-0 !pb-20">
        <img src="/images/coming-soon.png" alt="Coming Soon" className="h-64" />
      </div>
    </div>
  );
}
