const MenuButton = (props: { onClick: () => void; sidebarOpen: boolean }) => {
  const { onClick, sidebarOpen } = props;

  const handleClick = () => {
    onClick();
  };

  /**
   *
   * @returns string
   */
  const barStyle = () => {
    const notActiveStyles = `w-full h-[2px] bg-gray-900`;
    const activeStyles = `w-full h-[2px] bg-gray-900 transition-transform duration-200 ease-in-out`;
    return sidebarOpen ? activeStyles : notActiveStyles;
  };

  return (
    <>
      <div
        className="h-[30px] w-[30px] cursor-pointer flex flex-col justify-center"
        role="button"
        onClick={handleClick}
      >
        <div
          className={
            sidebarOpen
              ? `${barStyle()} !translate-y-[3px] rotate-[-45deg]`
              : `${barStyle()} translate-y-[-3px]`
          }
        />
        <div
          className={
            sidebarOpen
              ? `${barStyle()} !translate-y-[2px] rotate-[45deg]`
              : `${barStyle()} translate-y-[3px]`
          }
        />
        <div
          className={sidebarOpen ? "hidden" : `${barStyle()} translate-y-[9px]`}
        />
      </div>
    </>
  );
};

export default MenuButton;
