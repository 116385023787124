import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ColumnDef } from "@tanstack/react-table";

import TableSkeleton from "../TableSkeleton";

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T, any>[];
  loading: boolean;
}

const Table: React.FC<any> = <T extends object>({
  data,
  columns,
  loading = true,
}: ReactTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (loading) {
    return <TableSkeleton />;
  }

  return (
    <table className="flex w-full md:border-collapse md:border-block md:table-auto md:table">
      <thead className="hidden md:table-header-group">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className="py-5 px-4 text-left uppercase whitespace-nowrap"
                key={header.id}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody className="w-full md:[&>*:nth-child(odd)]:bg-slate-100">
        {!data.length && (
          <tr>
            <td colSpan={columns.length} className="py-3 px-4 text-center">
              No Record Found
            </td>
          </tr>
        )}

        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className="flex flex-col mb-5 md:mb-0 bg-slate-100 md:bg-inherit md:table-row"
          >
            {row.getVisibleCells().map((cell) => (
              <td
                className="flex justify-between py-3 px-4 text-left whitespace-nowrap md:table-cell"
                key={cell.id}
              >
                <span className="cell-header block font-semibold text-gray-600 md:hidden">
                  {`${cell.column.columnDef.header}`}
                </span>
                <span className="cell-content">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </span>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
