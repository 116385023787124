import { Business } from "../services/queries/business/types";
import { User } from "../services/queries/user/types";

type AllTableUnions = User | Business;
/**
 *
 * @param dateString - in this format: 2023-06-16T15:50:27.596Z
 * @param returnIsToday - when true, it returns date - in this format: "8:58 PM, Today", else returns default format
 * @returns date - in this format: 8:58 PM, Jun 7, 2023
 */
export function formatDate(dateString: string, returnIsToday: boolean = true) {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const time = date.toLocaleTimeString("en-US", options);

  const now = new Date();
  const isToday = date.toDateString() === now.toDateString();

  if (isToday && returnIsToday) {
    return `${time}, Today`;
  } else {
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${time}, ${formattedDate}`;
  }
}

/**
 * pagination positions
 */
export const positions = {
  topLeft: "top-0",
  topRight: "top-0 right-0",
  bottomLeft: "bottom-0",
  bottomRight: "bottom-0 right-0",
} as const;

export function getIDOfLastItem(tableData: AllTableUnions[]): string | null {
  if (!tableData || !tableData.length) return null;

  const record = tableData[tableData.length - 1];
  return record.id || null;
}

export function getIDOfFirstItem(tableData: AllTableUnions[]): string | null {
  if (!tableData || !tableData.length) return null;

  const record = tableData[0];
  return record.id || null;
}

export function capitalizeWords(inputString: string): string {
  const words = inputString.toLowerCase().split(" ");
  const capitalizedWords = words.map((word) => {
    if (!word.length) return;

    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(" ");
}

export function copyToClipboard(str: string, cb?: () => void) {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  cb?.();
}

//format number with comma function
export function formatNumberWithComma(value: string) {
  const cleanedText = value.replace(/[^0-9]/g, "");
  const formattedText = cleanedText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedText;
}

export enum AppRoutes {
  HOME = "/",
  DASHBOARD = "/dashboard",
  LOGIN = "/login",
}
