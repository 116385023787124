import { useQuery } from "@tanstack/react-query";

import { apiClient } from "../services/api";
import { Rate } from "../services/queries/rates/types";
import { ApiRoutes } from "../constants";
import { logError } from "../utils/sentry";

export const useRates = () => {
  const fetchRates = async () => {
    try {
      const { data } = await apiClient.get({
        url: `${ApiRoutes.RATES}?pageNumber=1&pageSize=12&orderBy=created&order=desc&concise=true`,
      });
      return data.list;
    } catch (err) {
      logError(err);
    }
  };

  const { data, isLoading, isSuccess, error, isError, refetch, isFetching } =
    useQuery<Rate[], Error>({
      queryKey: ["rates"],
      queryFn: fetchRates,
      initialData: [],
    });

  return { data, isLoading, isSuccess, error, isError, refetch, isFetching };
};
