import { useState, useEffect, useCallback } from "react";
import {
  Outlet,
  createFileRoute,
  useNavigate,
  useRouter,
  Link,
  redirect,
} from "@tanstack/react-router";
import { getAuth } from "firebase/auth";
import { useQueryClient } from "@tanstack/react-query";

import { routes } from "../constants";
import { signOut } from "../utils/firebase";
import useWindowSize from "../hooks/useWindowSize";
import MenuButton from "../components/MenuButton";
import { AppRoutes } from "../utils";
import { LOGOUT, useAppState } from "../contexts/AppContext";

function getPageTitle(pathname: string) {
  const currentRoute = routes.find((route) => {
    const routeParts = route.path.split("/").filter(Boolean);
    const pathnameParts = pathname.split("/").filter(Boolean);

    if (routeParts.length !== pathnameParts.length) {
      return false;
    }

    return routeParts.every((part, index) => {
      return part === pathnameParts[index] || part.startsWith(":");
    });
  });

  return currentRoute?.path === AppRoutes.DASHBOARD
    ? "Welcome Back."
    : currentRoute?.title;
}

export const Route = createFileRoute("/_app")({
  notFoundComponent: () => {
    return <p>The Page you requested was not found</p>;
  },
  beforeLoad: ({
    context: {
      app: {
        state: { user },
      },
    },
  }) => {
    if (!user) {
      return redirect({ to: AppRoutes.LOGIN });
    }
  },
  component: () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {
      dispatch,
      state: { user },
    } = useAppState();

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {
      history: {
        location: { pathname },
      },
    } = useRouter();

    const auth = getAuth();

    const handleLogout = useCallback(async () => {
      await signOut(auth);
      queryClient.removeQueries();
      localStorage.clear();
      dispatch({ type: LOGOUT });

      // uing setTimeout to ensure state is fully dispatched
      setTimeout(() => {
        navigate({ to: AppRoutes.LOGIN });
      }, 0);
    }, [auth, dispatch, navigate, queryClient]);

    // close the sidebar on mobile when the user navigates to a new page
    useEffect(() => {
      if (pathname && sidebarOpen) {
        setSidebarOpen(false);
      }
    }, [pathname]);

    return (
      <div className="app-layout min-h-screen bg-appbg relative">
        <aside
          className={`absolute md:fixed z-10 top-0 bottom-0 w-64 bg-white col-span-1 flex flex-col pl-3 pt-6 ${
            sidebarOpen ? "left-0" : "left-[-64rem] md:left-0"
          } transition-all !duration-500`}
        >
          <div className="mb-14">
            <Link className="flex" to={AppRoutes.DASHBOARD}>
              <img src="/logo.svg" alt="Passport Logo" width={30} />
              <p className="ml-3 text-2xl">
                <span className="text-primary">Passport</span>
                <span className="text-secondary">monie</span>
              </p>
            </Link>
          </div>

          <nav className="flex flex-col grow pb-4 pr-4">
            <ul className="flex flex-col grow">
              {routes &&
                routes.length > 0 &&
                routes
                  .filter((route) => route.isAppRoute && route.showInSideNav)
                  .map((route) => (
                    <li
                      key={route.title}
                      className={`flex items-center w-full rounded mb-2 transition-all duration-300 ${
                        location.pathname === route.path
                          ? "bg-blue-100"
                          : "hover:bg-blue-200"
                      }`}
                    >
                      <Link
                        className="flex items-center pl-4 py-3 w-full"
                        to={route.path}
                        activeProps={{
                          className: "font-bold text-blue-800",
                        }}
                      >
                        <img
                          className="mr-3 h-5"
                          src={route.iconUrl}
                          alt="Nav Icon"
                        />
                        {route.title}
                      </Link>
                    </li>
                  ))}
            </ul>

            <button
              className="flex rounded pl-4 py-3 items-center hover:bg-blue-200"
              onClick={handleLogout}
            >
              <img
                className="mr-3 h-5"
                src="/images/logout.png"
                alt="Nav Icon"
              />
              Sign Out
            </button>
          </nav>
        </aside>

        <main className="md:ml-64 px-6 pt-6 flex flex-col">
          <nav className="mb-14 flex gap-3 items-center justify-between">
            <div>
              <h2 className="text-2xl">{getPageTitle(pathname)}</h2>
            </div>

            <div>
              <div className="mobile-menu md:hidden">
                <MenuButton
                  onClick={() => setSidebarOpen((prev) => !prev)}
                  {...{ sidebarOpen }}
                />
              </div>

              <span className="text-large hidden md:block">{`${user?.firstName} ${user?.lastName}`}</span>
            </div>
          </nav>

          <Outlet />
        </main>
      </div>
    );
  },
});
