import { useState } from "react";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  SAVE_USER,
  SAVE_BUSINESS,
  useAppState,
  SAVE_AUTH_TOKEN,
} from "../../contexts/AppContext";
import { auth, signUp } from "../../utils/firebase/index";
import { showToast } from "../../utils/toast";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { validationSchema } from "../../schemas/ValidationSchema";
import { Terms, PrivacyPolicy, getAuthErrorMessage } from "../../constants";
import { apiClient } from "../../services/api";
import { AppRoutes } from "../../utils";
import "../../override.css";

interface FormProps {
  businessName: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  referredBy: string;
}
export const Route: any = createFileRoute("/_auth/signup")({
  component: Signup,
});

function Signup() {
  const { dispatch } = useAppState();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handlePhoneNumberChange = (value: string) => {
    setValue("phone", value);
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormProps>({ mode: "onSubmit", reValidateMode: "onChange" });

  const onSubmit: SubmitHandler<FormProps> = async ({
    email,
    password,
    firstName,
    lastName,
    businessName,
    phone,
    referredBy,
  }) => {
    setIsLoading(true);

    try {
      // authenticate wit firebase and save user in state
      const { user } = await signUp(auth, email, password);

      // get the token for api calls
      const token = await user?.getIdToken();
      dispatch({ type: SAVE_AUTH_TOKEN, payload: token });
      localStorage.setItem("token", JSON.stringify(token));

      // build the create business request data
      const createBusinessRequest = {
        email,
        firstName,
        lastName,
        name: businessName,
        phone,
        referredBy,
        createdBy: user.uid,
      };

      // create the business
      const { data: businessId } = await apiClient.post({
        url: "businesses",
        body: createBusinessRequest,
      });

      // save business in state
      const business = {
        id: businessId,
        created: new Date().toLocaleString(),
        ...createBusinessRequest,
      };
      dispatch({ type: SAVE_BUSINESS, payload: business });
      localStorage.setItem("business", JSON.stringify(business));

      const userPayload = {
        id: user.uid,
        firstName,
        lastName,
        businessId,
        created: new Date().toLocaleString(),
      };

      dispatch({
        type: SAVE_USER,
        payload: userPayload,
      });
      localStorage.setItem("user", JSON.stringify(userPayload));

      // uing setTimeout to ensure state is fully dispatched
      setTimeout(() => {
        navigate({ to: AppRoutes.DASHBOARD });
      }, 0);

      showToast("Signup successful 👍🏻", "success");
    } catch (error) {
      setIsLoading(false);
      const message = getAuthErrorMessage(error);

      showToast(message, "error");
    }
  };

  return (
    <div>
      <h4 className="text-2xl font-bold text-center mt-10 mb-10">
        Create account
      </h4>

      <div className="form flex justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex md:flex-row flex-col md:gap-4 w-96 md:w-full">
            <div className="form-control mb-5 md:w-96">
              <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                First Name
              </label>
              <TextInput
                className="md:w-full px-6 py-3 rounded-lg"
                {...register("firstName", {
                  required: "First Name is required",
                })}
                error={errors.firstName}
              />
            </div>

            <div className="form-control mb-5 md:w-96">
              <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Last Name
              </label>
              <TextInput
                className="w-full px-6 py-3 rounded-lg"
                {...register("lastName", { required: "Last Name is required" })}
                error={errors.lastName}
              />
            </div>
          </div>
          <div className="flex md:flex-row flex-col md:gap-4 w-96 md:w-full">
            <div className="appearance-none block md:w-1/2 mb-5 text-gray-700 rounded-lg ">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Phone number
              </label>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    country=""
                    onChange={handlePhoneNumberChange}
                    value="+234"
                    inputStyle={{
                      width: "100%",
                      height: "46px",
                    }}
                    containerStyle={{
                      border: ".react-tel-input .form-control:focus",
                    }}
                  />
                )}
                rules={{ required: "This field is required" }}
              />
              {errors.phone && (
                <p className="text-red-500 text-xs italic">
                  Input phone number{" "}
                </p>
              )}
            </div>
            <div className="form-control mb-5 md:w-96 space-x-0">
              <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Business Name
              </label>
              <TextInput
                className="w-full px-6 py-3 rounded-lg"
                {...register("businessName", {
                  required: "Business Name is required",
                })}
                error={errors.businessName}
              />
            </div>
          </div>

          <div className="flex md:flex-row flex-col md:gap-4 w-96 md:w-full">
            <div className="form-control mb-5 md:w-96">
              <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Email
              </label>
              <TextInput
                className=" px-6 py-3 rounded-lg"
                {...register("email", {
                  required: "Business Email Address is required",
                })}
                error={errors.email}
              />
            </div>

            <div className="form-control mb-8 md:w-96">
              <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Password
              </label>
              <div className="relative">
                <TextInput
                  type={showPassword ? "text" : "password"}
                  className="px-6 py-3 rounded-lg"
                  {...register("password", validationSchema.password)}
                  error={errors.password}
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="absolute right-0 top-0 pr-3 pt-4 flex items-center cursor-pointer"
                >
                  {showPassword ? <IoIosEye /> : <IoIosEyeOff />}
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col w-96 md:w-full">
            <div className="form-control mb-5 md:w-96">
              <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Referral Code
              </label>
              <TextInput
                className="w-full px-6 py-3 rounded-lg"
                {...register("referredBy")}
                error={errors.referredBy}
              />
            </div>
          </div>
          <div className="flex justify-center md:flex-row flex-col mt-8 w-96 md:w-full">
            <Button
              className="bg-primary hover:bg-accent transition-colors text-white py-3 hover:border-accent rounded-lg border-primary md:w-96"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Creating..." : "Create Account"}
            </Button>
          </div>
          <div className="text-black text-center mt-5">
            Already have an account?
            <Link to="/login" className="text-primary ml-1">
              Login
            </Link>
          </div>
          <div className="text-center mt-6 text-sm mb-4 w-96 md:w-full">
            By creating an account, you agree to our{" "}
            <a href={Terms}>
              <span className="text-primary">Terms</span>
            </a>{" "}
            and our{" "}
            <a href={PrivacyPolicy}>
              <span className="text-primary">Privary Policy</span>
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}
