import { useState } from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { sendPasswordResetEmail } from "firebase/auth";

import { auth } from "../../utils/firebase";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { showToast } from "../../utils/toast";

interface FormProps {
  email: string;
}

export const Route: any = createFileRoute("/_auth/forgotPassword")({
  component: ForgotPassword,
});

function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<FormProps> = async ({ email }) => {
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(auth, email);
      showToast(
        "Password reset successfully. Check your email for instructions.",
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({ mode: "onSubmit", reValidateMode: "onChange" });

  return (
    <div>
      <h4 className="text-2xl font-bold text-center mt-56 mb-2">
        Forgot Password?
      </h4>

      <p className="text-center mb-10">
        To reset your password, enter the email address you used when you signed
        up.
      </p>
      <div className="form flex justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control mb-8 w-96">
            <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Email
            </label>
            <TextInput
              className="px-6 py-3 rounded-lg"
              {...register("email", { required: "Email Address is required " })}
              placeholder="Email"
              error={errors.email}
            />
          </div>
          <Button
            className="bg-primary hover:bg-accent transition-colors  text-white w-full py-3 ml-1 hover:border-accent rounded-lg border-primary"
            type="submit"
            disabled={isLoading}
          >
            Reset Password
          </Button>
          <div className="text-black text-center mt-5">
            <Link to="/login" className="text-primary">
              ← Back to Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
