import React, { useEffect, useReducer } from "react";

import { Business } from "../services/queries/business/types";
import { User } from "../services/queries/user/types";
import AppSkeleton from "../components/Skeleton/AppSkeleton";

type AppState = {
  authLoading: boolean;
  authToken: string;
  user: User | null;
  business: Business | null;
  orderDetails: Order | null;
  businessOrderId: string | null;
  activeSendFormScreen: string;
};

type Order = {
  baseAmount: string;
  quoteAmount: string;
  baseCurrency: string;
  quoteCurrency: string;
  rateId?: string;
};

export type AppContextValue = {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
};

interface Props {
  children: React.ReactNode;
}

export const AUTH_LOADING = "AUTH_LOADING";
export const SAVE_AUTH_TOKEN = "SAVE_AUTH_TOKEN";
export const SAVE_USER = "SAVE_USER";
export const SAVE_BUSINESS = "SAVE_BUSINESS";
export const LOGOUT = "LOGOUT";
export const SAVE_ORDER_DETAILS = "SAVE_ORDER_DETAILS";
export const SAVE_BUSINESS_ORDER_ID = "SAVE_BUSINESS_ORDER_ID";
export const RESET_ORDER_DETAILS = "RESET_ORDER_DETAILS";
export const UPDATE_SEND_FORM_SECTION = "UPDATE_SEND_FORM_SECTION";

type AppAction =
  | { type: typeof AUTH_LOADING; payload: boolean }
  | { type: typeof SAVE_AUTH_TOKEN; payload: string }
  | { type: typeof SAVE_USER; payload: User }
  | { type: typeof SAVE_BUSINESS; payload: Business }
  | { type: typeof LOGOUT }
  | {
      type: typeof SAVE_ORDER_DETAILS;
      payload: Order;
    }
  | {
      type: typeof SAVE_BUSINESS_ORDER_ID;
      payload: string;
    }
  | {
      type: typeof RESET_ORDER_DETAILS;
    }
  | { type: typeof UPDATE_SEND_FORM_SECTION; payload: string };

const initialState: AppState = {
  authLoading: true,
  authToken: "",
  user: null,
  business: null,
  orderDetails: null,
  businessOrderId: null,
  activeSendFormScreen: "amountInput",
};

function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case AUTH_LOADING:
      return { ...state, authLoading: action.payload };
    case SAVE_AUTH_TOKEN:
      return { ...state, authToken: action.payload };
    case SAVE_USER:
      return { ...state, user: action.payload, authLoading: false };
    case SAVE_BUSINESS:
      return { ...state, business: action.payload };
    case SAVE_ORDER_DETAILS:
      return { ...state, orderDetails: action.payload };
    case RESET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: null,
        activeSendFormScreen: "amountInput",
      };
    case SAVE_BUSINESS_ORDER_ID:
      return { ...state, businessOrderId: action.payload };
    case UPDATE_SEND_FORM_SECTION:
      return { ...state, activeSendFormScreen: action.payload };
    case LOGOUT:
      return { ...initialState, authLoading: false };
    default:
      return state;
  }
}

export const AppContext = React.createContext<AppContextValue>({
  dispatch: () => {},
  state: initialState,
});

export const useAppState = (): AppContextValue => React.useContext(AppContext);

export type AppStateRouterType = ReturnType<typeof useAppState>;

const AppProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // check if data is stored in localStorage
    const user = localStorage.getItem("user");
    const business = localStorage.getItem("business");
    const token = localStorage.getItem("token");

    if (user && business && token) {
      dispatch({ type: SAVE_AUTH_TOKEN, payload: token });
      dispatch({ type: SAVE_BUSINESS, payload: JSON.parse(business) });
      dispatch({ type: SAVE_USER, payload: JSON.parse(user) });
      dispatch({ type: AUTH_LOADING, payload: false });
      return;
    }

    dispatch({ type: LOGOUT });
  }, []);

  return (
    <AppContext.Provider
      value={{
        dispatch,
        state,
      }}
    >
      {state.authLoading && <AppSkeleton />}
      {!state.authLoading && children}
    </AppContext.Provider>
  );
};

export default AppProvider;
