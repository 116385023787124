import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  signOut,
  signInWithEmailAndPassword,
  getAuth,
  createUserWithEmailAndPassword,
  Auth,
} from "firebase/auth";
import { Messaging, getMessaging, getToken } from "firebase/messaging";

import { User } from "../../services/queries/user/types";
import { usersCollection } from "../../constants/index";

import { firebaseConfig } from "./config";

let auth: Auth,
  messaging: Messaging,
  accessTokenPushNotification: string | undefined;

if (!firebase.apps.length) {
  try {
    firebase.initializeApp(firebaseConfig);
  } catch (err) {}
}

try {
  auth = getAuth();
} catch (error) {}

try {
  messaging = getMessaging();
} catch (error) {}

const login = signInWithEmailAndPassword;
const signUp = createUserWithEmailAndPassword;

async function saveTokenToDatabase(token: string, userId: string) {
  try {
    await firebase
      .firestore()
      .collection(usersCollection)
      .doc(userId)
      .update({
        webTokens: firebase.firestore.FieldValue.arrayUnion(token),
      });
  } catch (error) {}
}

async function getTokenAsync(messaging: any, user: User | null) {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_MESSAGING_VAPID_KEY,
    });
    if (currentToken) {
      accessTokenPushNotification = currentToken;
    }
    if (user) {
      return saveTokenToDatabase(currentToken, user.id);
    }
  } catch (err) {}
}

async function getUserToken() {
  try {
    const user = auth.currentUser;
    if (!user) {
      return null;
    }

    return user.getIdToken();
  } catch (err) {}
}

export {
  auth,
  signUp,
  login,
  signOut,
  messaging,
  getTokenAsync,
  getUserToken,
  accessTokenPushNotification,
};
