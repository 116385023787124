import { createFileRoute } from "@tanstack/react-router";
import Spinner from "../../../components/Spinner";
import DetailItemCard from "../../../components/DetailItemCard";
import { useGetTransaction } from "../../../services/queries/transactions";

export const Route: any = createFileRoute("/_app/transactions/$id")({
  component: Transaction,
});

function Transaction() {
  let { id } = Route.useParams();

  const { isLoading, data } = useGetTransaction(id);

  if (isLoading) {
    return (
      <div className="w-full h-80 flex justify-center items-center font">
        <Spinner className="w-16 h-16" />
      </div>
    );
  }

  const transaction = data?.data;

  if (!transaction) {
    return <h2>No Transaction found</h2>;
  }

  const {
    orderNo,
    currencyPair,
    baseCurrency,
    baseAmount,
    quoteCurrency,
    quoteAmount,
    recipientType,
    paymentMethod,
    institution,
    accountName,
    accountNumber,
    reason,
    status,
    created,
    business: { name: businessName },
  } = transaction;

  return (
    <div className="transaction-page">
      <section className="currency grid grid-cols-1 md:grid-cols-3 gap-4 ">
        <DetailItemCard title="Order No." value={`${orderNo}`} />
        <DetailItemCard title="Business Name" value={`${businessName}`} />
        <DetailItemCard title="Status" value={`${status}`} />
      </section>
      <hr className="mt-10 mb-10" />
      <section className="exchange-details grid grid-cols-1 md:grid-cols-3 gap-4">
        <DetailItemCard title="Currency Pair" value={`${currencyPair}`} />
        <DetailItemCard
          title="From"
          value={`${baseCurrency} ${baseAmount}`}
          textToCopy={`${baseAmount}`}
          toastMessage="Copied"
          withCopy
        />
        <DetailItemCard
          title="To"
          value={`${quoteCurrency} ${Number(quoteAmount).toFixed(2)}`}
          textToCopy={`${quoteAmount}`}
          toastMessage="Copied"
          withCopy
        />
      </section>
      <hr className="mt-10 mb-10" />
      <section className="payment-details grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
        <DetailItemCard title="Recipient Type" value={`${recipientType}`} />
        <DetailItemCard title="Payment Method" value={`${paymentMethod}`} />
      </section>

      <section className="payment-details grid grid-cols-1 md:grid-cols-3 gap-4">
        <DetailItemCard
          title="Institution"
          value={`${institution}`}
          textToCopy={`${institution}`}
          toastMessage="Copied"
          withCopy
        />
        <DetailItemCard
          title="Account Name"
          value={`${accountName}`}
          textToCopy={`${accountName}`}
          toastMessage="Copied"
          withCopy
        />
        <DetailItemCard
          title="Account Number"
          value={`${accountNumber}`}
          textToCopy={`${accountNumber}`}
          toastMessage="Copied"
          withCopy
        />
      </section>
      <hr className="mt-10 mb-10" />
      <section className="payment-details grid grid-cols-1 md:grid-cols-3 gap-4">
        <DetailItemCard title="Reason" value={`${reason}`} />
        <DetailItemCard title="Created" value={`${created}`} />
      </section>
    </div>
  );
}
