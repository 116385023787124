import React from "react";

const AppSkeleton = () => {
  return (
    <div className="flex h-screen bg-gray-100">
      <nav className="w-64 h-full bg-gray-800 p-5 space-y-4">
        <div className="h-10 bg-gray-700 rounded animate-pulse"></div>
        <div className="h-10 bg-gray-700 rounded animate-pulse"></div>
        <div className="h-10 bg-gray-700 rounded animate-pulse"></div>
        <div className="h-10 bg-gray-700 rounded animate-pulse"></div>
      </nav>

      <div className="flex-1 flex flex-col">
        <div className="h-20 bg-white shadow-md flex items-center p-6">
          <div className="h-8 w-1/5 bg-gray-300 rounded animate-pulse"></div>
          <div className="flex w-1/6 justify-end ml-auto">
            <div className="h-8 w-1/6 bg-gray-300 rounded animate-pulse"></div>
          </div>
        </div>

        <main className="flex-1 bg-gray-200 p-6 space-y-3 overflow-auto">
          <div className="h-12 bg-gray-300 rounded animate-pulse"></div>
          <div className="h-12 bg-gray-300 rounded animate-pulse"></div>
          <div className="h-12 bg-gray-300 rounded animate-pulse"></div>
          <div className="h-12 bg-gray-300 rounded animate-pulse"></div>
          <div className="h-12 bg-gray-300 rounded animate-pulse"></div>
        </main>
      </div>
    </div>
  );
};

export default AppSkeleton;
