import { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CgSpinner } from "react-icons/cg";
import { FaHourglassStart } from "react-icons/fa";
import { Link } from "@tanstack/react-router";

import Spacer from "../../components/Spacer";
import Dialog from "../Dialog";
import Tooltip from "../Tooltip";
import { useAppState, RESET_ORDER_DETAILS } from "../../contexts/AppContext";
import {
  paymentMethodLabels,
  Currency,
  paymentDetails,
  ApiRoutes,
  labels,
} from "../../constants";
import { apiClient } from "../../services/api";
import { logError } from "../../utils/sentry";
import { formatNumberWithComma } from "../../utils";

const PaymentDetails = () => {
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const {
    state: { orderDetails, businessOrderId },
    dispatch,
  } = useAppState();

  const paymentDetailLabel =
    paymentMethodLabels[orderDetails?.baseCurrency as Currency];
  const paymentDetail = paymentDetails.find(
    (detail) => detail.currency === orderDetails?.baseCurrency,
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await apiClient.patch({
        url: `${ApiRoutes.BUSINESS_ORDERS}/${businessOrderId}`,
        body: {
          status: "PAYMENT_SENT",
        },
      });
      setLoading(false);
      setShowDialog(true);
    } catch (err) {
      logError(err);
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    dispatch({ type: RESET_ORDER_DETAILS });
    setShowDialog(false);
  };

  return (
    <>
      {showDialog && (
        <Dialog isOpen={showDialog}>
          <div className="bg-white p-8 flex flex-col items-center">
            <FaHourglassStart color="rgb(59 130 246)" size={40} />
            <Spacer />
            <p>
              We are processing your transaction and your recipient will be
              credited shortly.
            </p>
            <Link to="/transactions" className="w-full flex justify-center">
              <button
                type="button"
                className="bg-blue-500 text-white w-1/2 rounded-lg py-4 my-4"
                onClick={handleModalClose}
              >
                Close
              </button>
            </Link>
          </div>
        </Dialog>
      )}
      <div className="text-center">
        <p className="font-bold text-xl">{labels.transferDetails}</p>
        <Spacer />
        <p>{labels.amountToSend}</p>
        <p className="font-bold text-xl">
          {orderDetails?.baseCurrency}{" "}
          {formatNumberWithComma(orderDetails?.baseAmount as string)}
        </p>
        <p>
          {labels.recipientGets} {orderDetails?.quoteCurrency}{" "}
          {formatNumberWithComma(orderDetails?.quoteAmount as string)}
        </p>
        <Spacer size="md" />
        <div className="bg-blue-50 rounded-lg py-4">
          <p className="font-bold">{labels.payIntoAccount}</p>
          <Spacer />
          <div className="flex justify-center gap-2">
            <p>
              {paymentDetailLabel.accountNumber}: {paymentDetail?.accountNumber}
            </p>
            <Tooltip text={copied ? labels.copied : labels.copy}>
              <CopyToClipboard
                text={paymentDetail?.accountNumber as string}
                onCopy={() => setCopied(true)}
              >
                <span className="flex items-center gap-1 cursor-pointer hover:font-bold">
                  {labels.copy} <MdContentCopy />
                </span>
              </CopyToClipboard>
            </Tooltip>
          </div>
          <p>
            {paymentDetailLabel.accountName}: {paymentDetail?.accountName}
          </p>
          <p>
            {paymentDetailLabel.institution}: {paymentDetail?.institution}
          </p>
        </div>
        <Spacer />
        <button
          type="button"
          className="bg-blue-500 text-white w-full rounded-lg py-4 my-4 flex items-center justify-center"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && <CgSpinner className="animate-spin h-5 w-5 mr-3" />}{" "}
          {labels.paidText}
        </button>
      </div>
    </>
  );
};

export default PaymentDetails;
