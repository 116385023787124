import cx from "classnames";

interface Props {
  className?: string;
}

export default function Spinner(props: Props) {
  const { className } = props;
  return (
    <div className="flex flex-col">
      <div className="flex flex-row space-x-4">
        <div
          className={cx(
            "w-8 h-8 rounded-full animate-spin border-4 border-dashed border-accent border-t-transparent",
            className,
          )}
        ></div>
      </div>
    </div>
  );
}
