import { useState, useEffect } from "react";
import Select from "react-select";
import { NumberFormatValues, NumericFormat } from "react-number-format";

import Spacer from "../../components/Spacer";
import ExchangeRateIndicator from "../../components/ExchangeRateIndicator";
import { useAppState, SAVE_ORDER_DETAILS } from "../../contexts/AppContext";
import { currencies, currencyDetails, labels } from "../../constants";
import { useRates } from "../../hooks/useRates";
import { Rate } from "../../services/queries/rates/types";
import { showToast } from "../../utils/toast";

interface Props {
  handleContinue: () => void;
}

interface OptionProps {
  value: string;
  label: string;
}

const AmountInput = ({ handleContinue }: Props) => {
  const {
    data: rates,
    isLoading,
    isFetching,
    isError: isRateError,
  } = useRates();
  const { state, dispatch } = useAppState();

  const [rateReverse, setRateReverse] = useState(false);
  const [baseValue, setBaseValue] = useState<string>(
    state.orderDetails?.baseAmount || "",
  );
  const [quoteValue, setQuoteValue] = useState<string>(
    state.orderDetails?.quoteAmount || "",
  );
  const [currencyOptions, setCurrencyOptions] = useState<OptionProps[]>([]);
  const [baseCurrency, setBaseCurrency] = useState<OptionProps | null>(
    currencies[0],
  );
  const [quoteCurrency, setQuoteCurrency] = useState<OptionProps | null>(null);
  const [rate, setRate] = useState<Rate | null>(null);
  const isDisabled = !baseValue || !baseCurrency || !quoteCurrency || !rate;

  useEffect(() => {
    const currenciesAvailableToSendTo = currencyDetails.find(
      (item) => item.currency === baseCurrency?.value,
    );
    if (!currenciesAvailableToSendTo) return;

    setCurrencyOptions(currenciesAvailableToSendTo.canSend);
    setQuoteCurrency(currenciesAvailableToSendTo.canSend[0]);
  }, [baseCurrency]);

  const handleContinueClick = () => {
    dispatch({
      type: SAVE_ORDER_DETAILS,
      payload: {
        baseAmount: baseValue as string,
        quoteAmount: quoteValue as string,
        baseCurrency: baseCurrency?.value as string,
        quoteCurrency: quoteCurrency?.value as string,
        rateId: rate?.id as string,
      },
    });
    handleContinue();
  };

  useEffect(() => {
    if (!rates.length) return;
    const currencyPair = `${baseCurrency?.value}/${quoteCurrency?.value}`;
    const rate = rates?.find((rate) => rate.currencyPair === currencyPair);
    if (rate) {
      setRate(rate);
    } else {
      setRate(null);
    }
  }, [baseCurrency, quoteCurrency, rates]);

  const handleRateReverse = () => setRateReverse((prev) => !prev);

  const handleBaseValueChange = ({ value }: NumberFormatValues) => {
    setBaseValue(value);
  };

  useEffect(() => {
    if (!rate && baseValue) return;
    const calculatedQuoteValue = Math.round(
      Number(baseValue) * Number(rate?.finalRate),
    );
    setQuoteValue(calculatedQuoteValue.toString());
  }, [baseValue, rate]);

  if (isRateError) {
    showToast("Error fetching rate", "error");
  }

  return (
    <div>
      <Spacer />
      <p>{labels.youSend}</p>
      <Spacer />
      <div className="border rounded-lg p-3 flex gap-4">
        <Select
          className="react-select-container"
          options={[{ value: "NGN", label: "🇳🇬 NGN" }]}
          value={baseCurrency}
          onChange={setBaseCurrency}
        />
        <NumericFormat
          className="flex-1 focus:outline-none"
          placeholder={"0.0"}
          thousandSeparator=","
          value={baseValue}
          onValueChange={handleBaseValueChange}
        />
      </div>
      <Spacer size="md" />
      <ExchangeRateIndicator
        baseCurrency={
          rateReverse
            ? (quoteCurrency?.value as string)
            : (baseCurrency?.value as string)
        }
        quoteCurrency={
          rateReverse
            ? (baseCurrency?.value as string)
            : (quoteCurrency?.value as string)
        }
        rate={
          rate && rateReverse
            ? ((1 / rate.finalRate) as number).toFixed(5)
            : (rate?.finalRate as number)
        }
        handleRateReverse={handleRateReverse}
        isLoading={!rate && (isLoading || isFetching)}
      />
      <Spacer size="sm" />
      <p>{labels.recipientGets}</p>
      <Spacer />
      <div className="border rounded-lg p-3 flex gap-4">
        <Select
          className="react-select-container"
          options={currencyOptions}
          value={quoteCurrency}
          onChange={setQuoteCurrency}
        />
        <NumericFormat
          className="flex-1 focus:outline-none"
          placeholder={"0.0"}
          thousandSeparator=","
          disabled
          value={quoteValue}
          onValueChange={({ value }) => {
            setQuoteValue(value);
          }}
        />
      </div>
      <Spacer size="lg" />
      <button
        type="button"
        className="bg-blue-500 text-white w-full rounded-lg py-4 my-4 disabled:opacity-45 disabled:cursor-not-allowed"
        onClick={handleContinueClick}
        disabled={isDisabled}
      >
        {labels.continue}
      </button>
    </div>
  );
};

export default AmountInput;
