import { createFileRoute } from "@tanstack/react-router";

export const Route: any = createFileRoute("/_app/dashboard/")({
  component: Dashboard,
});

interface WalletProps {
  currency: string;
  currencySymbol: string;
  balance: string;
  handleDepositClick?: () => void;
  handleWithdrawClick?: () => void;
}

function WalletCard({ currency, currencySymbol, balance }: WalletProps) {
  return (
    <div className="flex flex-col bg-white p-4 rounded-lg shadow-lg md:w-80">
      <h2 className="text-gray-700 font-extrabold text-lgn mb-5">{currency}</h2>

      <span className="text-3xl text-center text-green-600 mb-8">
        {currencySymbol} {balance}
      </span>

      <div className="flex justify-between gap-8">
        <button className="flex items-center bg-blue-400 hover:bg-blue-700 hover:text-white text-gray-700 font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
          <img
            src="/images/deposit.png"
            className="mr-3 h-5"
            alt="Deposit Icon"
          />
          Deposit
        </button>
        <button className="flex items-center bg-red-400 hover:bg-red-700 hover:text-white text-gray-700 font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
          <img
            src="/images/deposit.png"
            className="mr-3 h-5"
            alt="Withdraw Icon"
          />
          Withdraw
        </button>
      </div>
    </div>
  );
}

function Dashboard() {
  return (
    <div className="flex flex-col">
      <h2 className="text-lg font-semibold mb-5">Balances</h2>

      <div className="flex flex-wrap gap-5">
        <WalletCard currency="NGN" currencySymbol="&#x20A6;" balance="0" />
        <WalletCard currency="USD" currencySymbol="$" balance="0" />
      </div>
    </div>
  );
}
