import { HTMLAttributeAnchorTarget } from "react";

import CopyButton from "../CopyButton";
import { Link } from "@tanstack/react-router";

type IProp = {
  title: string;
  value: string;
  textToCopy?: string;
  toastMessage?: string;
  link?: string;
  withCopy?: boolean;
  target?: HTMLAttributeAnchorTarget;
};

const DetailItemCard = (props: IProp) => {
  const {
    title,
    value,
    textToCopy = "",
    toastMessage = "",
    link = "",
    withCopy = false,
    target = "_self",
  } = props;

  return (
    <div className="detail-item-card-component">
      <div className="flex flex-col lg:mb-0">
        <h4 className="font-black">{title}:</h4>
        <span className="flex gap-2">
          {link ? (
            <Link
              title={title}
              className="text-blue-500"
              target={target}
              to={link}
              rel="noopener noreferrer"
            >
              {value}
            </Link>
          ) : (
            <p>{value}</p>
          )}
          {withCopy && (
            <CopyButton
              {...{ toastMessage }}
              textToCopy={textToCopy || String(value)}
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default DetailItemCard;
