import axiosInstance from "../axiosConfig";

type Request = {
  url: string;
  body?: any;
};

const deleteRequest = async ({ url, body: data }: Request) => {
  const { data: response } = await axiosInstance.delete(url, {
    data,
  });

  return response;
};

const get = async ({ url }: Request) => {
  const { data: response } = await axiosInstance.get(url);

  return response;
};

const post = async ({ url, body }: Request) => {
  const { data: response } = await axiosInstance.post(url, body);

  return response;
};

const patch = async ({ url, body }: Request) => {
  const { data: response } = await axiosInstance.patch(url, body);

  return response;
};

const put = async ({ url, body }: Request) => {
  const { data: response } = await axiosInstance.put(url, body);

  return response;
};

export const apiClient = {
  delete: deleteRequest,
  get,
  patch,
  post,
  put,
};
