import { copyToClipboard } from "../../utils";
import { showToast } from "../../utils/toast";
import { Copy } from "../AppIcons";

interface CopyButtonProps {
  toastMessage?: string;
  textToCopy: string;
}

const CopyButton = ({
  textToCopy,
  toastMessage = "Copied to Clipboard",
}: CopyButtonProps) => {
  return (
    <img
      onClick={() =>
        copyToClipboard(textToCopy, () => showToast(toastMessage, "info"))
      }
      height={20}
      width={20}
      src={Copy}
      alt="copy icon"
      className="cursor-pointer border border-solid border-gray-500 rounded-full"
    />
  );
};

export default CopyButton;
