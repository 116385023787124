import { HiOutlineSwitchVertical } from "react-icons/hi";
import { ImSpinner3 } from "react-icons/im";

interface Props {
  baseCurrency: string;
  quoteCurrency: string;
  rate: number | string | null;
  handleRateReverse: () => void;
  isLoading?: boolean;
}

const ExchangeRateIndicator = ({
  baseCurrency,
  quoteCurrency,
  rate,
  handleRateReverse,
  isLoading,
}: Props) => {
  return (
    <div className="bg-green-50 py-1 px-2 border border-green-500 rounded-lg w-fit flex items-center gap-2">
      <p className="text-green-700 flex items-center font-bold">
        {baseCurrency} 1 = {quoteCurrency}&nbsp;
        {isLoading ? <ImSpinner3 className="animate-spin" /> : rate}
      </p>

      {!isLoading && (
        <div onClick={handleRateReverse}>
          <HiOutlineSwitchVertical className="text-green-700 cursor-pointer transform transition-all" />
        </div>
      )}
    </div>
  );
};

export default ExchangeRateIndicator;
