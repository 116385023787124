import { Link, createFileRoute } from "@tanstack/react-router";
import { createColumnHelper } from "@tanstack/react-table";

import TransactionsList from "../../../components/Table";
import {
  transactionsResourcePath,
  useGetTransactions,
} from "../../../services/queries/transactions";
import useFilters, { Params } from "../../../hooks/useFilters";
import { Transaction } from "../../../services/queries/transactions/types";
import { useAppState } from "../../../contexts/AppContext";
import Pagination from "../../../components/Pagination";
import { formatDate, positions } from "../../../utils";
import { Eye } from "../../../components/AppIcons";

type TransactionsTableColumns = Transaction & {
  ellipsis: "ellipsis";
  actions: "actions";
};

const columnHelper = createColumnHelper<TransactionsTableColumns>();

const columns = [
  columnHelper.accessor("orderNo", {
    cell: (info) => info.getValue(),
    header: "Order No",
  }),
  columnHelper.accessor((info) => `${info.baseCurrency} ${info.baseAmount}`, {
    cell: (info) => info.getValue(),
    header: "From",
  }),
  columnHelper.accessor((info) => `${info.quoteCurrency} ${info.quoteAmount}`, {
    cell: (info) => info.getValue(),
    header: "To",
  }),
  columnHelper.accessor("accountNumber", {
    cell: ({ row }) => {
      const { institution, accountName, accountNumber } = row.original;

      return (
        <div className="recipient-details">
          <p className="m-0">{institution}</p>
          <p className="m-0">{accountName}</p>
          <p className="m-0">{accountNumber}</p>
        </div>
      );
    },
    header: "Recipient",
  }),
  columnHelper.accessor("status", {
    cell: (info) => info.getValue(),
    header: "Status",
  }),
  columnHelper.accessor("created", {
    cell: (info) => formatDate(info.getValue()),
    header: "Created",
  }),
  columnHelper.accessor("actions", {
    cell: ({ row }) => {
      const { id } = row.original;

      return (
        <div className="flex gap-4">
          <Link
            to={`/transactions/${id}`}
            className="w-64 md:w-full border p-3 flex justify-center bg-accent"
          >
            <img height={20} width={20} src={Eye} alt="View Transaction" />
          </Link>
        </div>
      );
    },
    header: "",
  }),
];

export const Route: any = createFileRoute("/_app/transactions/")({
  component: Transactions,
});

function Transactions() {
  const {
    state: { business },
  } = useAppState();
  const { bottomRight } = positions;

  let extraFilters: Partial<Params> = {};
  if (business && business.id) {
    extraFilters.businessId = business.id;
  }

  const {
    url,
    filters: { pageNumber, pageSize },
    setFilters,
  } = useFilters(transactionsResourcePath, extraFilters);

  const { isLoading, data } = useGetTransactions(url.href, pageNumber);

  const transactions = data?.data?.list || [];
  const totalPages = Math.ceil(Number(data?.data?.meta?.total || 0) / pageSize);

  return (
    <div className="transactions-page">
      <div className="bg-white overflow-x-auto relative p-6">
        <TransactionsList
          loading={isLoading}
          data={transactions}
          columns={columns}
        />

        {totalPages > 1 && (
          <Pagination
            totalPages={totalPages}
            position={bottomRight}
            data={transactions}
            pageSize={pageSize}
            setFilters={setFilters}
          />
        )}
      </div>
    </div>
  );
}
