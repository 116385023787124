import React, { ReactElement } from "react";
import cx from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "neutral";
}

const variants = {
  primary:
    "bg-accent hover:bg-darkaccent border-accent hover:border-darkaccent text-white disabled:bg-neutral-100 disabled:border-neutral-100",
  neutral:
    "bg-neutral-100 hover:bg-neutral-200 border-neutral-100 hover:border-neutral-100 text-primary-complement",
};

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  variant = "primary",
  ...props
}) => {
  return (
    <button
      {...props}
      className={cx(
        "flex-shrink-0 bg-accent border-accent text-sm border-2 text-white py-2 px-10 mt-4 ml-1 rounded content-end",
        className,
      )}
    >
      {children}
    </button>
  );
};

export default Button;
