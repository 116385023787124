import { useState } from "react";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";

import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { auth, login, signOut } from "../../utils/firebase/index";
import { showToast } from "../../utils/toast";
import { apiClient } from "../../services/api";
import {
  useAppState,
  SAVE_USER,
  SAVE_AUTH_TOKEN,
  SAVE_BUSINESS,
} from "../../contexts/AppContext";
import { getAuthErrorMessage, errorMessages } from "../../constants";
import { AppRoutes } from "../../utils";

interface FormProps {
  email: string;
  password: string;
}

export const Route: any = createFileRoute("/_auth/login")({
  component: Login,
});

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { dispatch } = useAppState();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({ mode: "onSubmit", reValidateMode: "onChange" });

  const onSubmit: SubmitHandler<FormProps> = async ({ email, password }) => {
    setIsLoading(true);
    try {
      const { user } = await login(auth, email, password);

      const { data: businessUser } = await apiClient.get({
        url: `businesses-users/${user.uid}`,
      });

      dispatch({
        type: SAVE_USER,
        payload: businessUser,
      });
      localStorage.setItem("user", JSON.stringify(businessUser));

      const token = await user?.getIdToken();
      dispatch({ type: SAVE_AUTH_TOKEN, payload: token });
      localStorage.setItem("token", JSON.stringify(token));

      // get business id
      const { data: business } = await apiClient.get({
        url: `businesses/${businessUser.businessId}`,
      });

      if (!business) {
        setIsLoading(false);
        showToast(errorMessages.notBusinessUser, "error");
        signOut(auth);
        return;
      }

      dispatch({ type: SAVE_BUSINESS, payload: business });
      localStorage.setItem("business", JSON.stringify(business));

      // uing setTimeout to ensure state is fully dispatched
      setTimeout(() => {
        navigate({ to: AppRoutes.DASHBOARD });
      }, 0);
    } catch (error) {
      const message = getAuthErrorMessage(error);
      showToast(message, "error");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <h4 className="text-2xl font-bold text-center mt-52 mb-10">
        Welcome Back!
      </h4>

      <div className="form flex justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control mb-8 w-96">
            <TextInput
              className="w-full px-6 py-3 rounded-lg"
              {...register("email", { required: "Email Address is required " })}
              placeholder="Email"
              error={errors.email}
            />
          </div>

          <div className="form-control mb-5 relative">
            <TextInput
              type={showPassword ? "text" : "password"}
              className="w-full px-6 py-3 rounded-lg"
              {...register("password", { required: "Password is required" })}
              placeholder="Password"
              error={errors.password}
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            >
              {showPassword ? <IoIosEye /> : <IoIosEyeOff />}
            </div>
          </div>

          <div className="flex justify-end mt-1 cursor-pointer text-primary text-xs">
            <Link className=" text-primary text-sm" to="/forgotPassword">
              Forgot Password
            </Link>
          </div>

          <Button
            className="bg-primary hover:bg-accent transition-colors  text-white w-full py-3 mt-8 hover:border-accent rounded-lg border-primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Login"}
          </Button>

          <div className="text-black text-center mt-5 mb-4">
            Don't have an account?
            <Link to="/signup" className="text-primary ml-1">
              Create account
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
