import { useState } from "react";

import { baseUrl } from "../constants";

export interface Params {
  pageNumber: string;
  pageSize: number;
  orderBy: string;
  order: string;
  isPreviousPageQuery: boolean;
  businessId?: string;
}

export interface UseFiltersResult {
  url: URL;
  filters: Params;
  setFilters: React.Dispatch<React.SetStateAction<Params>>;
}

const buildApiUrl = (resourcePath: string, filters: Params): URL => {
  const {
    pageNumber,
    pageSize,
    orderBy,
    order,
    isPreviousPageQuery,
    businessId,
  } = filters;
  const resourceApiUrl = new URL(`${baseUrl}/${resourcePath}/`);
  resourceApiUrl.searchParams.append("pageNumber", pageNumber);
  resourceApiUrl.searchParams.append("pageSize", String(pageSize));
  resourceApiUrl.searchParams.append("orderBy", orderBy);
  resourceApiUrl.searchParams.append("order", order);
  isPreviousPageQuery &&
    resourceApiUrl.searchParams.append(
      "isPreviousPageQuery",
      `${isPreviousPageQuery}`,
    );
  businessId &&
    resourceApiUrl.searchParams.append("businessId", `${businessId}`);

  return resourceApiUrl;
};

const useFilters = (
  resourcePath: string,
  extraFilters?: Partial<Params>,
): UseFiltersResult => {
  const defaultFilters = {
    pageNumber: "1",
    pageSize: 10,
    orderBy: "created",
    order: "desc",
    isPreviousPageQuery: false,
  };

  const [filters, setFilters] = useState<Params>({
    ...defaultFilters,
    ...extraFilters,
  });

  const url = buildApiUrl(resourcePath, filters);

  return { url, filters, setFilters };
};

export default useFilters;
