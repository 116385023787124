import { useState } from "react";
import Select from "react-select";
import { BiArrowBack } from "react-icons/bi";
import { CgSpinner } from "react-icons/cg";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Spacer from "../Spacer";
import TextInput from "../TextInput";

import { paymentMethod, ApiRoutes, labels } from "../../constants";
import { useAppState, SAVE_BUSINESS_ORDER_ID } from "../../contexts/AppContext";
import { apiClient } from "../../services/api";
import { logError } from "../../utils/sentry";

interface Props {
  handleContinue: () => void;
  handleBack: () => void;
}

interface OrderFormProps {
  recipientType: string;
  paymentMethod: string;
  institution: string;
  accountName: string;
  accountNumber: string;
  reason: string;
}

const RecipientDetailsForm = ({ handleContinue, handleBack }: Props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<OrderFormProps>({ mode: "onSubmit", reValidateMode: "onChange" });

  const { state, dispatch } = useAppState();

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<OrderFormProps> = async () => {
    const values = getValues();
    const options = {
      baseCurrency: state?.orderDetails?.baseCurrency,
      quoteCurrency: state?.orderDetails?.quoteCurrency,
      baseAmount: Number(state?.orderDetails?.baseAmount),
      paymentMethod: values.paymentMethod,
      institution: values.institution,
      accountNumber: values.accountNumber,
      accountName: values.accountName,
      rateId: state?.orderDetails?.rateId,
      businessId: state?.business?.id,
    };

    setLoading(true);
    try {
      const { data } = await apiClient.post({
        url: ApiRoutes.BUSINESS_ORDERS,
        body: options,
      });
      setLoading(false);
      dispatch({ type: SAVE_BUSINESS_ORDER_ID, payload: data });
      handleContinue();
    } catch (err) {
      logError(err);
      setLoading(false);
    }
  };

  return (
    <>
      <div onClick={handleBack}>
        <BiArrowBack size={24} />
      </div>
      <Spacer size="md" />
      <p className="font-bold">{labels.recipientDetails}</p>
      <Spacer size="lg" />
      <div className="w-full">
        <p>{labels.paymentMethod}</p>
        <Spacer />
        <Controller
          name="paymentMethod"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Select
              options={paymentMethod}
              onChange={(newValue) => onChange(newValue?.value)}
            />
          )}
        />
        {errors.paymentMethod && (
          <p className="text-red-500 text-xs italic">
            {labels.selectPaymentMethod}
          </p>
        )}
      </div>
      <Spacer size="md" />
      <div className="w-100">
        <p>{labels.institutionOrBank}</p>
        <Spacer />
        <TextInput
          {...register("institution", { required: true })}
          placeholder={"E.g. Mpesa, First Bank"}
        />
        {errors.institution && (
          <p className="text-red-500 text-xs italic">
            {labels.enterInstitutionOrBank}
          </p>
        )}
      </div>
      <Spacer size="md" />
      <div className="w-full">
        <p>{labels.accountNumber}</p>
        <Spacer />
        <TextInput
          {...register("accountNumber", { required: true })}
          placeholder="0189234578"
        />
        {errors.accountNumber && (
          <p className="text-red-500 text-xs italic">
            {labels.enterAccountNumber}
          </p>
        )}
      </div>
      <Spacer size="md" />
      <div className="w-full">
        <p>{labels.accountName}</p>
        <Spacer />
        <TextInput
          {...register("accountName", { required: true })}
          placeholder="John Doe"
        />
        {errors.accountName && (
          <p className="text-red-500 text-xs italic">
            {labels.enterAccountName}
          </p>
        )}
      </div>
      <Spacer size="lg" />
      <button
        className="bg-blue-500 text-white w-full rounded-lg py-4 my-4 flex items-center justify-center"
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={loading}
      >
        {loading && <CgSpinner className="animate-spin h-5 w-5 mr-3" />}{" "}
        {labels.submit}
      </button>
    </>
  );
};

export default RecipientDetailsForm;
