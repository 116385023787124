import ReactPaginate from "react-paginate";
import { getIDOfFirstItem, getIDOfLastItem } from "../../utils";
import { Params } from "../../hooks/useFilters";

interface PaginationChangeProps {
  isPrevious: boolean;
  selected: number;
}
interface Props {
  pageSize: number;
  totalPages: number;
  position?: string;
  data: any; //change later
  setFilters: React.Dispatch<React.SetStateAction<Params>>;
}

const Pagination = ({
  totalPages,
  pageSize,
  position = "bottom-0",
  data,
  setFilters,
}: Props) => {
  const pageCount = Math.ceil(totalPages / pageSize);

  const handlePageClick = (isClicked: PaginationChangeProps): void => {
    const { isPrevious, selected } = isClicked;
    if (!data) return;

    // this prevents us from going backwards with the paginator when we are on the first page
    if (isPrevious && selected - 1 < 0) return;

    // this prevents us from going forwards when we are on the last page
    if (!isPrevious && selected + 1 >= pageCount) return;

    if (isPrevious) {
      const id = getIDOfFirstItem(data);
      if (!id) return;

      setFilters((prev: Params) => ({
        ...prev,
        pageNumber: id,
        isPreviousPageQuery: true,
      }));
      return;
    }

    const id = getIDOfLastItem(data);

    if (!id) return;

    setFilters((prev: Params) => ({
      ...prev,
      pageNumber: id,
      isPreviousPageQuery: false,
    }));
  };
  return (
    <ReactPaginate
      previousLabel={"← Previous"}
      nextLabel={"Next →"}
      pageCount={pageCount}
      initialPage={0}
      containerClassName={`absolute ${position} flex w-fit list-none cursor-pointer gap-2 my-3 p-2.5 items-center`}
      previousLinkClassName={"p-2.5 rounded-[5px] border-[1px] border-solid"}
      nextLinkClassName={"p-2.5 rounded-[5px] border-[1px] border-solid"}
      pageClassName={"p-2.5 rounded-[5px] border-[1px] border-solid hidden"}
      disabledLinkClassName={
        "border-slate-300 text-slate-300 cursor-not-allowed"
      }
      activeClassName={"bg-accent h-full"}
      breakClassName={"hidden"}
      onClick={({ isPrevious, selected }) =>
        handlePageClick({ isPrevious, selected })
      }
    />
  );
};

export default Pagination;
